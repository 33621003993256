<template lang="pug">
Editor(v-model="myValue" :init="init")
//- div
  //- pre {{ height }}
</template>

<script>
import TinyMCE from 'tinymce/tinymce';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/themes/modern/theme';

// Plugins
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textcolor';

import { Axios } from '@/plugins/axios'

export default {
  name: 'vcTinyMCE',
  components: {
    Editor
  },
  props: [ 'value', 'height' ],
  data() {
    return {
      init: {
        language_url: '/static/tinymce/langs/zh_CN.js', // 语言包的路径
        language: 'zh_CN',                              // 语言
        skin_url: '/static/tinymce/skins/lightgray',    // skin路径
        height: this.height || 150,                                    // 编辑器高度
        plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu',
        toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat',
        menubar: false,                                 // 顶部菜单栏显示
        branding: false,                                // 是否显示版权
        images_upload_handler: (blobInfo, success, failure) => {
          this.handleImgUpload(blobInfo, success, failure)
        }
      },
      myValue: this.value
    }
  },
  computed: {

  },
  methods: {
    handleImgUpload (blobInfo, success, failure) {
      // console.log('文件上传', blobInfo, success, failure)

      const formData = new FormData()
      formData.append('app', 'admin')
      formData.append('module', 'tinymce')
      formData.set('file', blobInfo.blob())

      Axios.post('attachment', formData).then(res => {
        success(res.src)
      }).catch(err => {
        failure('error', err)
      })
    }
  },
	watch: {
		// value (val) {
    //   if (val !== this.mde.value()) this.mde.value(val);
    // }
    value (newValue) {
      this.myValue = newValue;
    },
    myValue (newValue) {
      this.$emit('input', newValue);
    }
	},
  mounted() {
    //- console.log( this.value )
    TinyMCE.init({})
    /*
    // this.$refs.markdownEditor.simplemde.togglePreview();
    this.mde = new SimpleMDE({
      element: this.$refs.area,
      renderingConfig: {
        codeSyntaxHighlighting: true
      },
      // autoDownloadFontAwesome: false,
      status: false
    })
    this.mde.value(this.value)
    //- let testMarkdown = this.mde.markdown(this.mde.value());
    //- console.log(this.value, testMarkdown)

    this.mde.codemirror.on('change', () => {
      // this.mde.codemirror.setCursor(this.mde.codemirror.lineCount(), 0);
      this.$emit('input', this.mde.value());
      
      // 转换为 html
      // const converterValue = this.mde.markdown(this.mde.value());
      // const converterValue = SimpleMDE.prototype.markdown(this.mde.value());
      // console.log(converterValue)
    });
    */
	},
	beforeDestroy() {
    // this.mde.toTextArea()
    // this.mde = null
	}
}
</script>

<style lang="less">
</style>
